<template>
  <div class="content_body InventoryAllocationApply" v-loading="loading">
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form
            :inline="true"
            size="small"
            :model="searchForm"
            @keyup.enter.native="handleSearchInventoryAllocationApplyList"
          >
            <el-form-item v-if="OutboundEntityList.length > 1" label="调入方">
              <el-select
                v-model="searchForm.InboundEntityID"
                clearable
                filterable
                placeholder="请选择仓库/门店"
                :default-first-option="true"
                @change="handleSearchInventoryAllocationApplyList"
              >
                <el-option
                  v-for="item in OutboundEntityList"
                  :key="item.ID"
                  :label="item.EntityName"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="OutboundEntityList.length > 1" label="调出方">
              <el-select
                v-model="searchForm.OutboundEntityID"
                clearable
                filterable
                placeholder="请选择仓库/门店"
                :default-first-option="true"
                @change="handleSearchInventoryAllocationApplyList"
              >
                <el-option
                  v-for="item in OutboundEntityList"
                  :key="item.ID"
                  :label="item.EntityName"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="产品">
              <el-input
                v-model="searchForm.ProductName"
                placeholder="请输入产品名称、别名"
                clearable
                @clear="handleSearchInventoryAllocationApplyList"
              ></el-input>
            </el-form-item>
            <el-form-item label="制单日期">
              <el-date-picker
                v-model="searchForm.DateTime"
                unlink-panels
                type="daterange"
                range-separator="至"
                value-format="yyyy-MM-dd"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                clearable
                @change="handleSearchInventoryAllocationApplyList"
              ></el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                @click="handleSearchInventoryAllocationApplyList"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button
            type="primary"
            @click="addInventoryAllocationApply"
            size="small"
            v-prevent-click
            >新建调拨</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-tabs
      v-model="searchForm.BillStatus"
      type="border-card"
      @tab-click="tabsHandleClick"
    >
      <el-tab-pane label="全部" name="0"></el-tab-pane>
      <el-tab-pane name="10">
        <span slot="label"
          >待调出
          <el-badge
            v-if="StatusNumberInfo && StatusNumberInfo.BillStatus10 != 0"
            is-dot
          />
        </span>
      </el-tab-pane>
      <el-tab-pane name="20">
        <span slot="label"
          >待调入
          <el-badge
            v-if="StatusNumberInfo && StatusNumberInfo.BillStatus20 != 0"
            is-dot
          />
        </span>
      </el-tab-pane>
      <el-tab-pane name="30">
        <span slot="label">已驳回 </span>
      </el-tab-pane>
      <el-tab-pane name="40">
        <span slot="label"
          >已完成
          <!-- <el-badge v-if="StatusNumberInfo && StatusNumberInfo.BillStatus40 != 0" is-dot /> -->
        </span>
      </el-tab-pane>
      <!-- <el-tab-pane name="50">
        <span slot="label">已关闭
        </span>
      </el-tab-pane> -->
    </el-tabs>

    <el-table
      class="martp_10"
      size="small"
      :data="inventoryAllocationApply_list"
      tooltip-effect="light"
    >
      <el-table-column prop="ID" label="单据号"></el-table-column>
      <el-table-column
        prop="OutboundEntityName"
        label="调出方"
      ></el-table-column>
      <el-table-column
        prop="InboundEntityName"
        label="调入方"
      ></el-table-column>
      <el-table-column
        prop="BillStatus"
        label="单据状态"
        :formatter="BillStatusFormatter"
      ></el-table-column>
      <el-table-column prop="CreatedOn" label="制单日期"></el-table-column>
      <el-table-column prop="CreatedByName" label="申请人"></el-table-column>
      <el-table-column
        prop="Remark"
        label="备注"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column label="操作" width="160px">
        <template slot-scope="scope">
          <el-button
            v-if="
              scope.row.BillStatus == '10' && scope.row.OutboundEntityPermission
            "
            type="primary"
            size="small"
            @click="deliveryClick(scope.row)"
            v-prevent-click
            >审 核</el-button
          >
          <el-button
            v-if="
              scope.row.BillStatus == '20' && scope.row.InboundEntityPermission
            "
            type="primary"
            size="small"
            @click="inboundClick(scope.row)"
            v-prevent-click
            >入 库</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="showAllocateInfo(scope.row)"
            v-prevent-click
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pad_15 text_right">
      <el-pagination
        background
        v-if="paginations.total > 0"
        @current-change="InventoryAllocationApplyListHandleCurrentChange"
        :current-page.sync="paginations.page"
        :page-size="paginations.page_size"
        :layout="paginations.layout"
        :total="paginations.total"
      ></el-pagination>
    </div>

    <!-- 新建库存调拨  -->
    <el-dialog
      custom-class="InventoryAllocationApplyDialogClass"
      title="新建调拨"
      :visible.sync="addInventoryAllocationApplydialogVisible"
      @close="addInventoryAllocationApplydialogVisibleClose"
      width="1100px"
    >
      <div class="tip" style="margin-top: 0">基本信息</div>
      <el-form
        class="AddInventoryAllocationApplyInfoFrom"
        :inline="true"
        label-width="120px"
        size="small"
        :model="InventoryAllocationFrom"
        :rules="InventoryAllocationApplyDetailRules"
        ref="InventoryAllocationFromRef"
      >
        <el-form-item label="仓库门店：从" prop="OutboundEntityID">
          <el-select
            size="small"
            value-key="ID"
            :disabled ="InventoryAllocationFrom.InboundEntityID ==''"
            default-first-option
            v-model="InventoryAllocationFrom.OutboundEntityName"
            filterable
            placeholder="请选择仓库/门店"
            @focus="getInventoryAllocationApplyOutEntityNetwork(InventoryAllocationFrom.InboundEntityID)"
            @change="handleSelectOutboundEntity"
          >
            <el-option
              value-key="ID"
              v-for="item in OutboundEntityList"
              :key="item.ID"
              :label="item.EntityName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="调拨到" label-width="70px" prop="InboundEntityID">
          <el-select
            size="small"
            value-key="ID"
            default-first-option
            v-model="InventoryAllocationFrom.InboundEntityName"
            filterable
            placeholder="请选择仓库/门店"
            @change="handleSelectInboundEntity"
          >
            <el-option
              value-key="ID"
              v-for="item in InboundEntityNList"
              :key="item.ID"
              :label="item.EntityName"
              :value="item"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注信息：">
          <el-input
            type="textarea"
            style="width: 300px"
            :autosize="{ minRows: 1, maxRows: 3 }"
            v-model="InventoryAllocationFrom.Remark"
            placeholder="请输入备注信息"
            size="small"
          ></el-input>
        </el-form-item>
        <div class="tip">产品明细</div>
        <el-row>
          <el-col :span="24">
            <el-button type="primary" size="small" @click="addProducts"
              >添加产品</el-button
            >
            <el-button
              type="danger"
              size="small"
              @click="removeMultipleProduct"
              :disabled="removeDisabled"
              >删除产品</el-button
            >
          </el-col>
        </el-row>

        <el-table
          empty-text="暂无产品"
          size="small"
          class="martp_10"
          max-height="400px"
          :data="InventoryAllocationFrom.InventoryAllocationApplyDetail"
          @selection-change="removeHandleChangeSelectProduct"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <el-form-item
                :show-message="false"
                class="table_from_item_class"
                :prop="
                  'InventoryAllocationApplyDetail.' +
                  scope.$index +
                  '.ProductID'
                "
                :rules="InventoryAllocationApplyDetailRules.ProductID"
              >
                <el-select
                  v-model="scope.row.ProductID"
                  placeholder="请选择产品"
                  size="small"
                  filterable
                  remote
                  reserve-keyword
                  v-loadmore="loadMoreProduct"
                  :remote-method="searchProductListMethod"
                  :loading="productLoading"
                  @change="(val) => handleSelectProduct(val, scope.row)"
                  @focus="selectFocus"
                  @clear="clearSelectProduct(scope.row)"
                  :default-first-option="true"
                  popper-class="custom_popper_class"
                >
                  <el-option
                    v-for="item in ProductList"
                    :key="item.ID"
                    :label="item.ProductName"
                    :value="item.ID"
                  >
                    <div class="dis_flex flex_dir_column pad_5_0">
                      <div>
                        <span>{{ item.ProductName }}</span>
                        <span
                          class="color_gray marlt_5 font_12"
                          v-if="item.Alias"
                          >({{ item.Alias }})</span
                        >
                        <el-tag
                          v-if="item.IsLock"
                          size="mini"
                          type="warning"
                          effect="dark"
                          >{{ "盘点锁定" }}</el-tag
                        >
                      </div>
                      <div
                        :class="
                          item.ID == scope.row.ID
                            ? 'font_12 dis_flex  flex_x_between'
                            : 'color_gray font_12 dis_flex flex_x_between'
                        "
                      >
                        <span class="">{{ item.PCategoryName }}</span>
                        <span v-if="item.Specification">{{
                          item.Specification
                        }}</span>
                      </div>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>

          <el-table-column prop="Specification" label="产品规格">
            <template slot-scope="scope">{{
              scope.row.Specification
            }}</template>
          </el-table-column>
          <el-table-column prop="Quantity" label="调出方实物库存">
            <template slot-scope="scope">
              {{ scope.row.Quantity }} {{ scope.row.miniUnitName }}
            </template>
          </el-table-column>
          <el-table-column label="单位" prop="UnitName">
            <template slot-scope="scope">
              <el-form-item
                class="table_from_item_class"
                :prop="
                  'InventoryAllocationApplyDetail.' + scope.$index + '.UnitName'
                "
                :rules="InventoryAllocationApplyDetailRules.UnitID"
              >
                <el-select
                  value-key="UnitID"
                  v-model="scope.row.UnitName"
                  size="small"
                  filterable
                  placeholder="请选择单位"
                  @change="(val) => handleSelectProductUnit(val, scope.row)"
                  :default-first-option="true"
                >
                  <el-option
                    v-for="item in scope.row.Units"
                    :key="item.UnitID"
                    :label="item.UnitName"
                    :value="item"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="ApplyQuantity" label="申请调拨数量">
            <template slot-scope="scope">
              <el-form-item
                class="table_from_item_class"
                :show-message="false"
                :prop="
                  'InventoryAllocationApplyDetail.' +
                  scope.$index +
                  '.ApplyQuantity'
                "
                :rules="InventoryAllocationApplyDetailRules.ApplyQuantity"
              >
                <el-input
                  v-model="scope.row.ApplyQuantity"
                  size="small"
                  placeholder="请输入调拨数量"
                  @input="changeApplyQuantity(scope.row)"
                  validate-event
                  v-enter-number2
                  v-enterInt
                  min="0"
                  type="number"
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="Quantity" label="最小包装数量">
            <template slot-scope="scope" v-if="scope.row.ApplyQuantity">
              {{ scope.row.ApplyQuantity * scope.row.miniAmount }}
              {{ scope.row.miniUnitName }}
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <div slot="footer">
        <el-button
          @click="addInventoryAllocationApplydialogVisible = false"
          size="small"
          v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="saveInventoryAllocationClick"
          :loading="saveInventoryAllocationLoading"
          size="small"
          v-prevent-click
          >保 存</el-button
        >
      </div>
    </el-dialog>

    <!-- 调拨出库 详情  -->
    <el-dialog
      custom-class="InventoryAllocationApplyDialogClass"
      title="调拨详情"
      :visible.sync="allocateInfoDialogVisible"
      width="1100px"
    >
      <div class="tip">
        基本信息 -
        <span class="font_weight_600"
          >{{ billStatusToStr(allocateInfo.BillStatus) }}（{{
            allocateInfo.ID
          }}）</span
        >
      </div>
      <el-form
        class="InventoryAllocationApplyInfoFrom"
        :inline-message="true"
        label-width="120px"
        size="small"
        :model="allocateInfo"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="调入仓库/门店：">{{
              allocateInfo.InboundEntityName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人：">{{
              allocateInfo.CreatedByName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请时间：">{{
              allocateInfo.CreatedOn
            }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="调出仓库/门店：">{{
              allocateInfo.OutboundEntityName
            }}</el-form-item>
          </el-col>
          <el-col
            v-if="
              allocateInfo.BillStatus == 20 || allocateInfo.BillStatus == 40
            "
            :span="8"
          >
            <el-form-item label="出库操作人：">{{
              allocateInfo.ApprovedByName
            }}</el-form-item>
          </el-col>
          <el-col
            v-if="
              allocateInfo.BillStatus == 20 || allocateInfo.BillStatus == 40
            "
            :span="8"
          >
            <el-form-item label="出库时间：">{{
              allocateInfo.ApprovedOn
            }}</el-form-item>
          </el-col>
          <el-col v-if="allocateInfo.BillStatus == 30" :span="8">
            <el-form-item label="操作人：">{{
              allocateInfo.ApprovedByName
            }}</el-form-item>
          </el-col>
          <el-col v-if="allocateInfo.BillStatus == 30" :span="8">
            <el-form-item label="驳回时间：">{{
              allocateInfo.ApprovedOn
            }}</el-form-item>
          </el-col>
        </el-row>
        <el-row
          v-if="allocateInfo.BillStatus == 40 || allocateInfo.BillStatus == 20"
        >
          <el-col :span="8">
            <el-form-item label="调拨出库单号："
              >{{ allocateInfo.OutboundBillID }}
              <el-button
                v-if="allocateInfo.OutboundEntityPermission"
                type="text"
                @click="checkOutboundBillInfo"
                >查看</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              v-if="allocateInfo.BillStatus == '40'"
              label="调拨入库单号："
            >
              {{ allocateInfo.InboundBillID }}
              <el-button
                v-if="allocateInfo.InboundEntityPermission"
                type="text"
                @click="checkInbounBillInfo"
                >查看</el-button
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="allocateInfo.BillStatus == 30">
          <el-col :span="24">
            <el-form-item label="驳回原因：">{{
              allocateInfo.RejectReason
            }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注信息：">{{
              allocateInfo.Remark
            }}</el-form-item>
          </el-col>
        </el-row>

        <div class="tip">产品明细</div>
        <el-table
          empty-text="请点击“新增”添加产品"
          size="small"
          class="martp_10"
          max-height="400px"
          :data="allocateInfo.Detail"
        >
          <el-table-column label="产品" prop="ProductName">
            <template slot-scope="scope">
              <div>
                {{ scope.row.ProductName
                }}<span v-if="scope.row.Alias" class="color_gray font_12">
                  ({{ scope.row.Alias }})</span
                >
              </div>
              <div v-if="scope.row.Specification" class="color_gray font_12">
                规格：{{ scope.row.Specification }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="产品分类"
            prop="PCategoryName"
          ></el-table-column>
          <el-table-column prop="ApplyQuantity" label="申请调拨数量">
            <template slot-scope="scope">
              <div>{{ scope.row.ApplyQuantity }} {{ scope.row.UnitName }}</div>
              <div class="color_gray font_12">
                最小包装数量：{{ scope.row.ApplyMinimumUnitQuantity }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="
              allocateInfo.BillStatus == 20 || allocateInfo.BillStatus == 40
            "
            prop="InboundQuantity"
            label="实际出库数量"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.OutboundQuantity }} {{ scope.row.UnitName }}
              </div>
              <div class="color_gray font_12">
                最小包装数量：{{ scope.row.OutboundMinimumUnitQuantity }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            v-if="allocateInfo.BillStatus == 40"
            prop="InboundQuantity"
            label="实际入库数量"
          >
            <template slot-scope="scope">
              <div>
                {{ scope.row.InboundQuantity }} {{ scope.row.UnitName }}
              </div>
              <div class="color_gray font_12">
                最小包装数量：{{ scope.row.InboundMinimumUnitQuantity }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-dialog>

    <!-- 调拨出库 审批  -->
    <el-dialog
      custom-class="InventoryAllocationApplyDialogClass"
      title="调拨出库审核"
      :visible.sync="deliveryDialogVisible"
      width="1100px"
    >
      <div class="tip">基本信息</div>
      <el-form
        class="InventoryAllocationApplyInfoFrom"
        :inline="true"
        :inline-message="true"
        label-width="120px"
        size="small"
        :model="allocateInfo"
        :rules="allocateInfo.allocateInfoRules"
        ref="OutboundProductRef"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="调入仓库/门店：">{{
              allocateInfo.InboundEntityName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人：">{{
              allocateInfo.CreatedByName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请日期：">{{
              allocateInfo.CreatedOn
            }}</el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="调出仓库/门店：">{{
              allocateInfo.OutboundEntityName
            }}</el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="备注信息：" class="padbm_10 padtp_5">
              <el-input
                type="textarea"
                style="width: 280px"
                :autosize="{ minRows: 1, maxRows: 3 }"
                v-model="allocateInfo.outRemark"
                placeholder="请输入备注信息"
                size="small"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="tip">库存调拨明细</div>
        <el-table size="small" max-height="400px" :data="allocateInfo.Detail">
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <div>
                {{ scope.row.ProductName
                }}<span v-if="scope.row.Alias" class="color_gray font_12">
                  ({{ scope.row.Alias }})</span
                >
              </div>
              <div v-if="scope.row.Specification" class="color_gray font_12">
                规格：{{ scope.row.Specification }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="产品分类"
            prop="PCategoryName"
          ></el-table-column>
          <el-table-column prop="StockQuantity" label="调出方可用库存">
            <template slot-scope="scope">
              <div>
                {{ scope.row.StockQuantity }} {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ApplyQuantity" label="申请调拨数量">
            <template slot-scope="scope">
              <div>{{ scope.row.ApplyQuantity }} {{ scope.row.UnitName }}</div>
              <div class="color_gray font_12">
                最小包装数量：{{
                  scope.row.ApplyQuantity * scope.row.MinimumUnitAmount
                }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="OutboundQuantity" label="实际出库数量">
            <template slot-scope="scope">
              <el-form-item
                :show-message="false"
                :prop="'Detail.' + scope.$index + '.OutboundQuantity'"
                :rules="allocateInfo.allocateInfoRules.OutboundQuantity"
              >
                <el-input
                  v-model="scope.row.OutboundQuantity"
                  size="small"
                  placeholder="出库数量"
                  @input="changeOutboundQuantity(scope.row)"
                  validate-event
                  v-enter-number2
                  v-enterInt
                  min="0"
                  type="number"
                  :disabled="scope.row.OutboundIsLock"
                >
                  <template slot="append">{{ scope.row.UnitName }}</template>
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="最小包装数量">
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.OutboundIsLock"
                size="mini"
                type="warning"
                effect="dark"
                >{{ "盘点锁定" }}</el-tag
              >
              <div v-if="scope.row.OutboundQuantity">
                {{ scope.row.OutboundQuantity * scope.row.MinimumUnitAmount }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div slot="footer">
        <el-button
          @click="deliveryDialogVisible = false"
          size="small"
          v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="danger"
          @click="saveOutboundAllocationClick(false)"
          size="small"
          v-prevent-click
          >驳 回</el-button
        >
        <el-button
          type="primary"
          @click="saveOutboundAllocationClick(true)"
          :loading="deliveryLoading"
          size="small"
          v-prevent-click
          >确认出库</el-button
        >
      </div>
    </el-dialog>

    <!-- 驳回审核 -->
    <el-dialog
      width="600px"
      :visible.sync="finalRejectionDialogVisible"
      title="驳回原因"
    >
      <el-input
        type="textarea"
        :rows="4"
        v-model="finalRejection"
        placeholder="请输入驳回原因"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="finalRejectionDialogVisible = false" size="small"
          >取消</el-button
        >
        <el-button
          type="danger"
          @click="finalRejectApprovedClick"
          :loading="resetDeliveryLoading"
          size="small"
          >驳回审核</el-button
        >
      </span>
    </el-dialog>

    <!-- 调拨入库库 详情  -->
    <el-dialog
      custom-class="InventoryAllocationApplyDialogClass"
      title="调拨入库"
      :visible.sync="inboundDialogVisible"
      width="1000px"
    >
      <div class="tip">基本信息</div>
      <el-form
        class="InventoryAllocationApplyInfoFrom"
        :inline="true"
        :inline-message="true"
        label-width="120px"
        size="small"
        :model="allocateInfo"
        :rules="allocateInfo.allocateInfoRules"
        ref="InboundProductRef"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="调入仓库/门店：">{{
              allocateInfo.InboundEntityName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人：">{{
              allocateInfo.CreatedByName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请时间：">{{
              allocateInfo.CreatedOn
            }}</el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="8">
            <el-form-item label="调出仓库/门店：">{{
              allocateInfo.OutboundEntityName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库操作人：">{{
              allocateInfo.ApprovedByName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库时间：">{{
              allocateInfo.ApprovedOn
            }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="备注信息：" class="padbm_10 padtp_5">
            <el-input
              type="textarea"
              style="width: 280px"
              :autosize="{ minRows: 1, maxRows: 3 }"
              v-model="allocateInfo.inRemark"
              placeholder="请输入备注信息"
              size="small"
            ></el-input>
          </el-form-item>
        </el-row>
        <div class="tip martp_10">产品明细</div>
        <el-table
          empty-text="请点击“新增”添加产品"
          size="small"
          class="martp_10"
          max-height="400px"
          :data="allocateInfo.Detail"
        >
          <el-table-column label="产品名称" prop="ProductName">
            <template slot-scope="scope">
              <div>
                {{ scope.row.ProductName
                }}<span v-if="scope.row.Alias" class="color_gray font_12">
                  ({{ scope.row.Alias }})</span
                >
              </div>
              <div v-if="scope.row.Specification" class="color_gray font_12">
                规格：{{ scope.row.Specification }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="产品分类"
            prop="PCategoryName"
          ></el-table-column>
          <el-table-column prop="ApplyQuantity" label="申请调拨数量">
            <template slot-scope="scope">
              <div>{{ scope.row.ApplyQuantity }} {{ scope.row.UnitName }}</div>
              <div class="color_gray font_12">
                最小包装数量：{{
                  scope.row.ApplyQuantity * scope.row.MinimumUnitAmount
                }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="OutboundQuantity" label="实际调拨数量">
            <template slot-scope="scope">
              <div>
                {{ scope.row.OutboundQuantity }} {{ scope.row.UnitName }}
              </div>
              <div class="color_gray font_12">
                最小包装数量：{{
                  scope.row.OutboundQuantity * scope.row.MinimumUnitAmount
                }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="InboundQuantity" label="实际入库数量">
            <template slot-scope="scope">
              <el-form-item
                :show-message="false"
                :prop="'Detail.' + scope.$index + '.InboundQuantity'"
                :rules="allocateInfo.allocateInfoRules.InboundQuantity"
              >
                <el-input
                  v-model="scope.row.InboundQuantity"
                  size="small"
                  placeholder="入库数量"
                  @input="changeInboundQuantity(scope.row)"
                  validate-event
                  v-enter-number2
                  v-enterInt
                  min="0"
                  type="number"
                  :disabled="scope.row.InboundIsLock"
                >
                  <template slot="append">{{ scope.row.UnitName }}</template>
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="最小包装数量">
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.InboundIsLock"
                size="mini"
                type="warning"
                effect="dark"
                >{{ "盘点锁定" }}</el-tag
              >
              <div v-if="scope.row.InboundQuantity">
                {{ scope.row.InboundQuantity * scope.row.MinimumUnitAmount }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>

      <div slot="footer">
        <el-button
          @click="inboundDialogVisible = false"
          size="small"
          v-prevent-click
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="saveInboundClick"
          :loading="InboundLoading"
          size="small"
          v-prevent-click
          >确认入库</el-button
        >
      </div>
    </el-dialog>

    <!-- 入库 详情 -->
    <el-dialog
      custom-class="InventoryAllocationApplyDialogClass"
      title="调拨入库详情"
      :visible.sync="InboundInfoDialogVisible"
      width="1000px"
    >
      <div class="tip">基本信息</div>
      <el-form
        class="InventoryAllocationApplyInfoFrom"
        :inline="true"
        :inline-message="true"
        label-width="110px"
        size="small"
        :model="InboundInfo"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="入库仓库/门店：">{{
              InboundInfo.EntityName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人：">{{
              InboundInfo.CreatedByName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="入库日期：">{{
              InboundInfo.InDate
            }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="备注信息：">{{
              InboundInfo.Remark
            }}</el-form-item>
          </el-col>
        </el-row>
        <div class="tip martp_10">产品明细</div>
        <el-table size="small" max-height="450px" :data="InboundInfo.Detail">
          <el-table-column label="产品" prop="ProductName">
            <template slot-scope="scope">
              <div>
                {{ scope.row.ProductName
                }}<span v-if="scope.row.Alias" class="color_gray font_12">
                  ({{ scope.row.Alias }})</span
                >
              </div>
              <div v-if="scope.row.Specification" class="color_gray font_12">
                规格：{{ scope.row.Specification }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="产品分类"
            prop="PCategoryName"
          ></el-table-column>
          <el-table-column prop="Quantity" label="出库数量">
            <template slot-scope="scope">
              <div>{{ scope.row.Quantity || 0 }} {{ scope.row.UnitName }}</div>
              <div class="color_gray font_12">
                最小包装数量：{{ scope.row.MinimumUnitQuantity || 0 }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-dialog>

    <!-- 详情-->
    <el-dialog
      custom-class="InventoryAllocationApplyDialogClass"
      title="调拨出库详情"
      :visible.sync="OutboundInfoDialogVisible"
      width="1000px"
    >
      <div class="tip">基本信息</div>
      <el-form
        class="InventoryAllocationApplyInfoFrom"
        :inline="true"
        :inline-message="true"
        label-width="110px"
        size="small"
        :model="OutboundInfo"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="出库仓库/门店：">{{
              OutboundInfo.EntityName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人：">{{
              OutboundInfo.CreatedByName
            }}</el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="出库时间：" prop="OutDate">{{
              OutboundInfo.OutDate
            }}</el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="备注信息：">{{
              OutboundInfo.Remark
            }}</el-form-item>
          </el-col>
        </el-row>

        <div class="tip martp_10">产品明细</div>
        <el-table
          size="small"
          max-height="400px"
          :data="OutboundInfo.Detail"
          @selection-change="removeHandleChangeSelectProduct"
        >
          <el-table-column label="产品" prop="ProductName">
            <template slot-scope="scope">
              <div>
                {{ scope.row.ProductName
                }}<span v-if="scope.row.Alias" class="color_gray font_12">
                  ({{ scope.row.Alias }})</span
                >
              </div>
              <div v-if="scope.row.Specification" class="color_gray font_12">
                规格：{{ scope.row.Specification }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="产品分类"
            prop="PCategoryName"
          ></el-table-column>
          <el-table-column prop="Quantity" label="出库数量">
            <template slot-scope="scope">
              <div>{{ scope.row.Quantity || 0 }} {{ scope.row.UnitName }}</div>
              <div class="color_gray font_12">
                最小包装数量：{{ scope.row.MinimumUnitQuantity || 0 }}
                {{ scope.row.MinimumUnitName }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import permission from "@/components/js/permission.js";
import API from "@/api/PSI/Inventory/inventoryAllocationApply";
import APIStorage from "@/api/PSI/Purchase/storage";
import APIInquire from "@/api/PSI/Inventory/Inquire";
import APIStock from "@/api/PSI/Inventory/inventoryDetail";

import APIInbound from "@/api/PSI/Inventory/inventoryProductInbound";
import APIOutbound from "@/api/PSI/Inventory/inventoryProductOutbound";

import dateUtil from "@/components/js/date";

var Enumerable = require("linq");

export default {
  name: "InventoryAllocationApply",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      loading: false,
      saveInventoryAllocationLoading: false, // 保存load

      addInventoryAllocationApplydialogVisible: false,
      deliveryDialogVisible: false,
      inboundDialogVisible: false,
      allocateInfoDialogVisible: false,
      deliveryLoading: false,
      resetDeliveryLoading: false,
      finalRejectionDialogVisible: false,
      InboundLoading: false,

      InboundInfoDialogVisible: false, //入库详情
      OutboundInfoDialogVisible: false, // 出库
      ProductListTotal: 0, // 产品总数

      InventoryAllocationFrom: {
        InboundEntityID: "",
        InboundEntityName: "",
        OutboundEntityID: "",
        OutboundEntityName: "",
        Remark: "",
        InventoryAllocationApplyDetail: [],
      },

      InventoryAllocationApplyDetailRules: {
        OutboundEntityID: [
          {
            required: true,
            message: "请选择调出仓库",
            trigger: ["blur", "change"],
          },
        ],
        InboundEntityID: [
          {
            required: true,
            message: "请选择调入仓库",
            trigger: ["blur", "change"],
          },
        ],
        ApplyQuantity: [{ required: true, trigger: ["blur", "change"] }],
        ProductID: [{ required: true, trigger: ["blur", "change"] }],
        UnitID: [{ required: true, trigger: ["blur", "change"] }],
      },
      // 列表筛选条件
      searchForm: {
        InboundEntityID: "",
        OutboundEntityID: "",
        ProductName: "",
        DateTime: "",
        BillStatus: "0", //单据状态 0 全部，10：待审核、20：待配送、30：待入库、40：已驳回、50：已完成、60：已取消
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now() ? true : false;
        },
      },
      StatusNumberInfo: "", //状态统计
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next, jumper", // 翻页属性
      }, //需要给分页组件传的信息
      OutboundEntityList: [], // 调出仓库列表
      InboundEntityNList: [], // 调入仓库列表
      inventoryAllocationApply_list: [], //库存调拨 列表

      removeDisabled: true,
      ProductList: [],
      searchProductName: "",
      multipleProducts: [],
      selectProductList: [],
      allocateInfo: "", //  调拨
      finalRejection: "", //驳回原因

      InboundInfo: "",
      OutboundInfo: "",
    };
  },

  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    /**  搜索列表  */
    handleSearchInventoryAllocationApplyList() {
      var that = this;
      // that.searchForm.BillStatus="0";
      that.pickerOptions.page = 1;
      that.get_list_InventoryAllocationApply_netWork();
    },
    /**  tabbar切换  */
    tabsHandleClick() {
      let that = this;
      that.pickerOptions.page = 1;
      that.get_list_InventoryAllocationApply_netWork();
    },
    /**  分页点击  */
    InventoryAllocationApplyListHandleCurrentChange(page) {
      let that = this;
      that.pickerOptions.page = page;
      that.get_list_InventoryAllocationApply_netWork();
    },
    /**  订单状态数据格式化  */
    BillStatusFormatter(row) {
      let that = this;
      // 单据状态(10：待调出、20：待调入、30:已驳回、40:已完成、50:已取消)
      if (!row) {
        return "";
      }
      return that.billStatusToStr(row.BillStatus);
    },
    /**    */
    billStatusToStr(BillStatus) {
      if (!BillStatus) {
        return "";
      }
      switch (BillStatus) {
        case "10":
          return "待调出";
          break;
        case "20":
          return "待调入";
          break;
        case "30":
          return "已驳回";
          break;
        case "40":
          return "已完成";
          break;
        case "50":
          return "已取消";
          break;
      }
    },
    /** 关闭库存调拨   */
    closeAllocate(row) {
      let that = this;
      that
        .$confirm("关闭库存调拨后将无法继续调拨库存, 确定要关闭吗?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.get_cancel_InventoryAllocationApply_netWork(row.ID);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    /**  查看调拨详情  */
    showAllocateInfo(row) {
      let that = this;
      that.get_info_InventoryAllocationApply_netWork(row.ID, 1);
    },
    /**   新增库存调拨 */
    addInventoryAllocationApply() {
      let that = this;
      that.InventoryAllocationFrom = {
        InboundEntityID: "",
        InboundEntityName: "",
        OutboundEntityID: "",
        OutboundEntityName: "",
        Remark: "",
        InventoryAllocationApplyDetail: [],
      };

      if (that.OutboundEntityList.length == 1) {
        let outEntityItem = that.OutboundEntityList[0];
        that.InventoryAllocationFrom.OutboundEntityID = outEntityItem.ID;
        that.InventoryAllocationFrom.OutboundEntityName =
          outEntityItem.EntityName;
      }

      if (that.InboundEntityNList.length == 1) {
        let inEntityItem = that.InboundEntityNList[0];
        that.InventoryAllocationFrom.InboundEntityID = inEntityItem.ID;
        that.InventoryAllocationFrom.InboundEntityName =
          inEntityItem.EntityName;
      }

      that.addInventoryAllocationApplydialogVisible = true;
    },

    /**  选择调出仓库  */
    handleSelectOutboundEntity(row) {
      let that = this;
      if (that.InventoryAllocationFrom.InboundEntityID == row.ID) {
        that.InventoryAllocationFrom.InboundEntityID = "";
        that.InventoryAllocationFrom.InboundEntityName = "";
      }
      Enumerable.from(that.ProductList)
        .intersect(
          that.InventoryAllocationFrom.InventoryAllocationApplyDetail,
          (i) => i.ProductID
        )
        .forEach((item) => {
          that.$refs.multipleTable.toggleRowSelection(item, false);
        });

      that.InventoryAllocationFrom.OutboundEntityID = row.ID;
      that.InventoryAllocationFrom.InventoryAllocationApplyDetail = [];
      that.selectProductList = [];
      // 调入仓库 不可与调出仓库相同
      that.InboundEntityNList = Enumerable.from(that.tempInboundEntityNList)
        .where((i) => i.ID != row.ID)
        .toArray();
    },
    /**  选择调入仓库  */
    handleSelectInboundEntity(row) {
      let that = this;
      that.InventoryAllocationFrom.InboundEntityID = row.ID;
    },

    /**  添加调拨商品  */
    addProducts() {
      let that = this;
      that.$refs["InventoryAllocationFromRef"].validateField(
        "OutboundEntityID",
        (valid) => {
          if (valid != "请选择调出仓库") {
            that.InventoryAllocationFrom.InventoryAllocationApplyDetail.push({
              ProductID: "", // ID
              ProductName: "", // 名称
              Alias: "", // 别名
              Specification: "", // 规格
              UnitID: "", // 单位ID
              UnitName: "", // 单位名称
              miniUnitName: "", // 最小单位名称
              Units: [], // 单位集合
              Quantity: "", // 库存
              ApplyQuantity: "", // 申请数量
              miniApplyQuantity: "", // 申请数量最小单位
            });
          }
        }
      );
    },
    /**  下拉选择产品  */
    handleSelectProduct(item_ID, row) {
      var that = this;
      row.ApplyQuantity = "";
      let item = Enumerable.from(that.ProductList).firstOrDefault((i) => {
        return item_ID == i.ID;
      }, {});
      row.ProductID = item.ID;
      row.ProductName = item.ProductName;
      row.Units = item.Unit;
      row.Specification = item.Specification;
      row.Quantity = item.Quantity;

      let defaultUnit = Enumerable.from(item.Unit).firstOrDefault((i) => {
        return i.IsDefautSendReceive;
      }, -1);
      let miniUnit = Enumerable.from(item.Unit).firstOrDefault((i) => {
        return i.IsMinimumUnit;
      }, -1);
      if (defaultUnit != -1) {
        row.UnitID = defaultUnit.UnitID;
        row.UnitName = defaultUnit.UnitName;
        row.miniAmount = defaultUnit.Amount;
      }
      if (miniUnit != -1) {
        row.miniUnitName = miniUnit.UnitName;
        row.miniUnitID = miniUnit.UnitID;
      }
    },

    /** 获取焦点   */
    selectFocus() {
      let that = this;
      that.ProductList = [];
      that.searchProductListMethod("");
    },
    /** 远程搜索产品   */
    searchProductListMethod(query) {
      var that = this;
      that.productPageNum = 1;
      that.ProductList = [];
      that.getStockListNetwork(query);
    },
    /**  加载更多产品  */
    loadMoreProduct() {
      var that = this;
      if (that.ProductListTotal > that.ProductList.length) {
        that.productPageNum++;
        that.getStockListNetwork();
      }
    },
    /**  选择单位  */
    handleSelectProductUnit(val, row) {
      let that = this;
      row.ApplyQuantity = "";
      row.UnitID = val.UnitID;
      row.UnitName = val.UnitName;
      row.miniAmount = val.Amount;
    },

    /**  删除调拨产品  */
    removeMultipleProduct() {
      var that = this;
      if (that.multipleProducts.length > 0) {
        for (
          var i = 0;
          i <
          that.InventoryAllocationFrom.InventoryAllocationApplyDetail.length;
          i++
        ) {
          that.multipleProducts.forEach(function (item) {
            if (
              that.InventoryAllocationFrom.InventoryAllocationApplyDetail[i] ==
              item
            ) {
              that.InventoryAllocationFrom.InventoryAllocationApplyDetail.splice(
                i,
                1
              );
            }
          });
        }
      }
    },
    /**  选择将要删除的商品   */
    removeHandleChangeSelectProduct(selection) {
      this.multipleProducts = selection;
      if (this.multipleProducts.length > 0) {
        this.removeDisabled = false;
      } else {
        this.removeDisabled = true;
      }
    },
    /** 修改库存调拨数量   */
    changeApplyQuantity(row) {
      let that = this;
      row.ApplyQuantity = Math.floor(row.ApplyQuantity);
      let index_ =
        that.InventoryAllocationFrom.InventoryAllocationApplyDetail.indexOf(
          row
        );
      // 去除当前项 已申请的数量 最小单位总和
      let totoMiniQuantity = Enumerable.from(
        that.InventoryAllocationFrom.InventoryAllocationApplyDetail
      )
        .where((i, index) => {
          return i.ProductID == row.ProductID && index != index_;
        })
        .sum((i) => Number(i.ApplyQuantity) * Number(i.miniAmount));
      let tempApplyQuantity = row.ApplyQuantity * row.miniAmount;

      if (
        parseInt(tempApplyQuantity) + parseInt(totoMiniQuantity) >
        row.Quantity
      ) {
        row.ApplyQuantity = Math.floor(
          (parseInt(row.Quantity) - parseInt(totoMiniQuantity)) / row.miniAmount
        );
        that.$message.error({
          message: "申请调拨数量不可大于调出方库存数量",
          duration: 2000,
        });
        return;
      }
    },
    /** 关闭添加调拨    */
    addInventoryAllocationApplydialogVisibleClose() {
      // let that = this
      // that.$refs["InventoryAllocationFromRef"].clearValidate()
      // this.$refs.multipleTable.clearSelection()
    },
    /**  保存库存调拨  */
    saveInventoryAllocationClick() {
      let that = this;
      if (
        that.InventoryAllocationFrom.InventoryAllocationApplyDetail.length == 0
      ) {
        that.$message.error({
          message: "请选择调拨产品",
          duration: 2000,
        });
        return;
      }
      that.$refs["InventoryAllocationFromRef"].validate((valid) => {
        if (valid) {
          that.get_create_InventoryAllocationApply_netWork();
        }
      });
    },

    /**  调拨出库   */
    deliveryClick(row) {
      let that = this;
      that.get_info_InventoryAllocationApply_netWork(row.ID, 2);
    },

    /**  修改调拨出库数量  */
    changeOutboundQuantity(row) {
      let that = this;
      row.OutboundQuantity = Math.floor(row.OutboundQuantity);

      let index_ = that.allocateInfo.Detail.indexOf(row);
      // 去除当前项 已审批的数量 最小单位总和
      let totoMiniQuantity = Enumerable.from(that.allocateInfo.Detail)
        .where((i, index) => {
          return i.ProductID == row.ProductID && index != index_;
        })
        .sum((i) => Number(i.OutboundQuantity) * Number(i.MinimumUnitAmount));
      // 剩余库存数量
      let tempStockQuantuty = Math.floor(
        (parseFloat(row.StockQuantity) - parseFloat(totoMiniQuantity)) /
          row.MinimumUnitAmount
      );
      /**  当前库存大于申请数量  */
      if (tempStockQuantuty > row.ApplyQuantity * row.MinimumUnitAmount) {
        if (row.OutboundQuantity > row.ApplyQuantity) {
          row.OutboundQuantity = row.ApplyQuantity;
          that.$message.error({
            message: "出库数量不可大于申请数量",
            duration: 2000,
          });
          return;
        }
      } else {
        if (row.OutboundQuantity > tempStockQuantuty) {
          row.OutboundQuantity = tempStockQuantuty;
          that.$message.error({
            message: "出库数量不可大于当前库存数量",
            duration: 2000,
          });
          return;
        }
      }
    },
    /**  保存调拨出库  */
    saveOutboundAllocationClick(isPass) {
      let that = this;
      if (isPass) {
        that.$refs["OutboundProductRef"].validate((valid) => {
          if (valid) {
            var Products = Enumerable.from(that.allocateInfo.Detail)
              .select((i) => ({
                ID: i.ID,
                OutboundQuantity: i.OutboundQuantity,
                OutboundMinimumUnitQuantity:
                  parseFloat(i.OutboundQuantity) *
                  parseFloat(i.MinimumUnitAmount),
              }))
              .toArray();
            var params = {
              ID: that.allocateInfo.ID,
              BillStatus: "20",
              InventoryAllocationApplyDetail: Products,
              Remark: that.allocateInfo.outRemark,
            };
            that.deliveryLoading = true;
            that.get_outbound_InventoryAllocationApply_netWork(params);
          }
        });
      } else {
        that.finalRejectionDialogVisible = true;
      }
    },
    /** 驳回库存调拨  */
    finalRejectApprovedClick() {
      let that = this;
      var params = {
        ID: that.allocateInfo.ID,
        BillStatus: "30",
        RejectReason: that.finalRejection,
      };
      that.resetDeliveryLoading = true;
      that.get_outbound_InventoryAllocationApply_netWork(params);
    },

    /**  调拨入库   */
    inboundClick(row) {
      let that = this;
      that.get_info_InventoryAllocationApply_netWork(row.ID, 3);
    },

    /**  修改入库数量  */
    changeInboundQuantity(row) {
      let that = this;
      row.InboundQuantity = Math.floor(row.InboundQuantity);
      if (row.InboundQuantity > row.OutboundQuantity) {
        row.InboundQuantity = row.OutboundQuantity;
        that.$message.error({
          message: "入库数量不可大于出库数量",
          duration: 2000,
        });
        return;
      }
    },
    /**  保存调拨入库  */
    saveInboundClick() {
      let that = this;

      that.$refs["InboundProductRef"].validate((valid) => {
        if (valid) {
          var Products = Enumerable.from(that.allocateInfo.Detail)
            .select((i) => ({
              ID: i.ID,
              InboundQuantity: i.InboundQuantity,
              InboundMinimumUnitQuantity:
                parseFloat(i.MinimumUnitAmount) * parseFloat(i.InboundQuantity),
            }))
            .toArray();
          var params = {
            ID: that.allocateInfo.ID,
            InventoryAllocationApplyDetail: Products,
            Remark: that.allocateInfo.inRemark,
          };
          that.get_inbound_InventoryAllocationApply_netWork(params);
        }
      });
    },

    /** 查看出库明细   */
    checkOutboundBillInfo() {
      let that = this;
      let parmas = {
        ID: that.allocateInfo.OutboundBillID,
        InventoryType: "调拨出库",
      };
      that.get_info_ProductInventoryOutbound_netWork(parmas);
    },
    /**  查看入库明细  */
    checkInbounBillInfo() {
      let that = this;
      let parmas = {
        ID: that.allocateInfo.InboundBillID,
        InventoryType: "调拨入库",
      };
      that.get_info_inventoryProductInbound_netWork(parmas);
    },

    /**  =============================================  */

    /**  仓库列表  */
    getStorageEntityNetwork: function () {
      var that = this;
      var params = {};
      APIStorage.getpurchaseStorageEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.InboundEntityNList = res.Data;
            that.tempInboundEntityNList = JSON.parse(
              JSON.stringify(that.InboundEntityNList)
            );
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /**  仓库列表 无权限    */
    getInventoryAllocationApplyOutEntityNetwork: function (EntityID) {
      var that = this;
      var params = {EntityID};
      APIStorage.getInventoryAllocationApplyOutEntity(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.OutboundEntityList = res.Data;
            // that.InboundEntityNList = that.OutboundEntityList
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /** 查询 产品 库存列表 列表  */
    getStockListNetwork: function (searchProductName) {
      var that = this;
      var params = {
        ProductName: searchProductName,
        PageNum: that.productPageNum,
        EntityID: that.InventoryAllocationFrom.OutboundEntityID,
      };
      APIStock.get_stock_list_entityProductList(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.ProductListTotal = res.Total;
            that.ProductList.push.apply(that.ProductList, res.List);
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  7.1.库存调拨列表   */
    get_list_InventoryAllocationApply_netWork: function () {
      var that = this;
      that.loading = true;
      var params = {
        PageNum: that.pickerOptions.page,
        // ID: that.searchForm.ID,
        InboundEntityID: that.searchForm.InboundEntityID,
        OutboundEntityID: that.searchForm.OutboundEntityID,
        ProductName: that.searchForm.ProductName,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
        BillStatus:
          that.searchForm.BillStatus == "0" ? "" : that.searchForm.BillStatus,
      };
      API.inventoryAllocationApply_list(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.inventoryAllocationApply_list = res.List;
            that.paginations.page_size = res.PageSize;
            that.paginations.total = res.Total;
            that.get_billStatusNumber_InventoryAllocationApply_netWork();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    /** 7.2.库存调拨详情   */
    get_info_InventoryAllocationApply_netWork: function (ID, type) {
      var that = this;
      var params = {
        ID: ID,
      };
      API.inventoryAllocationApply_info(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.allocateInfo = res.Data;
            that.allocateInfo.allocateInfoRules = {
              OutboundQuantity: [
                {
                  required: true,
                  message: "这里是错误信息",
                  trigger: ["blur", "change"],
                },
              ],
              InboundQuantity: [
                { required: true, trigger: ["blur", "change"] },
              ],
            };

            switch (type) {
              case 1:
                that.allocateInfoDialogVisible = true;
                break;

              case 2:
                that.deliveryDialogVisible = true;
                break;

              case 3:
                that.inboundDialogVisible = true;
                break;
            }
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**   7.3.库存调拨单申请   */
    get_create_InventoryAllocationApply_netWork: function () {
      var that = this;
      var Products = Enumerable.from(
        that.InventoryAllocationFrom.InventoryAllocationApplyDetail
      )
        .select((i) => ({
          ProductID: i.ProductID,
          UnitID: i.UnitID,
          ApplyQuantity: i.ApplyQuantity,
          MinimumUnitID: i.miniUnitID,
          ApplyMinimumUnitQuantity: i.ApplyQuantity * i.miniAmount,
        }))
        .toArray();
      var params = {
        InboundEntityID: that.InventoryAllocationFrom.InboundEntityID,
        OutboundEntityID: that.InventoryAllocationFrom.OutboundEntityID,
        Remark: that.InventoryAllocationFrom.Remark,
        InventoryAllocationApplyDetail: Products,
      };
      API.inventoryAllocationApply_create(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "申请成功",
              duration: 2000,
            });
            that.get_list_InventoryAllocationApply_netWork();
            that.addInventoryAllocationApplydialogVisible = false;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /**  7.4.库存调拨出库   */
    get_outbound_InventoryAllocationApply_netWork: function (params) {
      var that = this;
      API.inventoryAllocationApply_outbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "操作成功",
              duration: 2000,
            });
            that.deliveryDialogVisible = false;
            that.finalRejectionDialogVisible = false;
            that.finalRejection = "";
            that.handleSearchInventoryAllocationApplyList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.deliveryLoading = false;
          that.resetDeliveryLoading = false;
        });
    },
    /**  7.5.库存调拨入库   */
    get_inbound_InventoryAllocationApply_netWork: function (params) {
      var that = this;
      API.inventoryAllocationApply_inbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message: "调拨入库成功",
              duration: 2000,
            });
            that.inboundDialogVisible = false;
            that.handleSearchInventoryAllocationApplyList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /** 7.6.库存调拨取消  */
    get_cancel_InventoryAllocationApply_netWork: function (ID) {
      var that = this;
      var params = {
        ID: ID,
      };
      API.inventoryAllocationApply_cancel(params)
        .then((res) => {
          console.log(
            "🚀 ~ file: inventoryAllocationApply.vue ~ line 494 ~ .then ~ res",
            res
          );
          if (res.StateCode == 200) {
            that.$message.success({
              message: "取消成功",
              duration: 2000,
            });
            that.handleSearchInventoryAllocationApplyList();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },
    /** 7.7.库存调拨状态统计  */
    get_billStatusNumber_InventoryAllocationApply_netWork: function () {
      var that = this;
      var params = {
        InboundEntityID: that.searchForm.InboundEntityID,
        OutboundEntityID: that.searchForm.OutboundEntityID,
        ProductName: that.searchForm.ProductName,
        StartDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[0],
        EndDate:
          that.searchForm.DateTime == null ? "" : that.searchForm.DateTime[1],
      };
      API.inventoryAllocationApply_billStatusNumber(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.StatusNumberInfo = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {});
    },

    /**   10.2.产品入库详情    */
    get_info_inventoryProductInbound_netWork: function (params) {
      var that = this;
      that.loading = true;
      APIInbound.get_info_inventoryProductInbound(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.InboundInfo = res.Data;
            that.InboundInfoDialogVisible = true;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },

    /**  8.2.产品出库详情   */
    get_info_ProductInventoryOutbound_netWork: function (params) {
      var that = this;
      that.loading = true;
      APIOutbound.getProductInventoryOutbound_info(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.OutboundInfoDialogVisible = true;
            that.OutboundInfo = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
  },
  /** 监听数据变化   */
  watch: {},
  /**  实例被挂载后调用  */
  mounted() {
    var that = this;
    // var date = new Date(),
    //   y = date.getFullYear(),
    //   m = date.getMonth();
    // that.searchForm.DateTime = [
    //   dateUtil.formatDate.format(new Date(y, m, 1), "YYYY-MM-DD"),
    //   dateUtil.formatDate.format(new Date(), "YYYY-MM-DD"),
    // ];
    that.getStorageEntityNetwork();
    that.get_list_InventoryAllocationApply_netWork();
  },
};
</script>

<style lang="scss">
.InventoryAllocationApply {
  .InventoryAllocationApplyDialogClass {
    .AddInventoryAllocationApplyInfoFrom {
      .el-input__inner {
        padding-right: 0;
      }
      .el-input-group__append {
        padding: 0 10px;
      }
    }
    .InventoryAllocationApplyInfoFrom {
      .el-form-item__label {
        font-size: 13px !important;
      }
      .el-form-item__content {
        font-size: 13px !important;
      }
      .el-form-item {
        margin-bottom: 0px;
      }
      .el-input__inner {
        padding-right: 0;
      }
      .el-input-group__append {
        padding: 0 10px;
      }
    }
    .table_from_item_class {
      font-size: 13px !important;
      margin-bottom: 0px;
    }
  }

  .input_type {
    .el-input-group__append {
      padding: 0 10px;
    }
  }
  .el-tabs--border-card {
    // border: 0px,0px,0px,0px !important;
    border-bottom: 0px;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0), 0 0 0px 0 rgba(0, 0, 0, 0);
    .el-tabs__content {
      padding: 0px !important;
    }
  }
  .IsLockProduct_list_back {
    background-color: #edf2fc;
    cursor: not-allowed;
  }
  .IsLockProduct_list_back:hover > td {
    background-color: #edf2fc !important;
  }
}

.custom_popper_class {
  .el-select-dropdown__item {
    line-height: normal;
    height: auto;
  }
}
</style>
