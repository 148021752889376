/**
 * Created by preference on 2020/11/25
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /** 7.1.库存调拨列表  */
  inventoryAllocationApply_list: params => {
    return API.POST('api/inventoryAllocationApply/list', params)
  },
  /**  7.2.库存调拨详情 */
  inventoryAllocationApply_info: params => {
    return API.POST('api/inventoryAllocationApply/info', params)
  },
  /** 7.3.库存调拨单申请  */
  inventoryAllocationApply_create: params => {
    return API.POST('api/inventoryAllocationApply/create', params)
  },
  /** 7.4.库存调拨出库  */
  inventoryAllocationApply_outbound: params => {
    return API.POST('api/inventoryAllocationApply/outbound', params)
  },
  /** 7.5.库存调拨入库  */
  inventoryAllocationApply_inbound: params => {
    return API.POST('api/inventoryAllocationApply/inbound', params)
  },
  /** 7.6.库存调拨取消  */
  inventoryAllocationApply_cancel: params => {
    return API.POST('api/inventoryAllocationApply/cancel', params)
  },
  /**  7.7.库存调拨状态统计 */
  inventoryAllocationApply_billStatusNumber: params => {
    return API.POST('api/inventoryAllocationApply/billStatusNumber', params)
  },
}