/**
 * Created by preference on 2020/11/26
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /**  8.1.产品出库列表 */
  getProductInventoryOutbound_list: params => {
    return API.POST('api/inventoryProductOutbound/list', params)
  },
  /**  8.2.产品出库详情 */
  getProductInventoryOutbound_info: params => {
    return API.POST('api/inventoryProductOutbound/info', params)
  },
  /** 8.3.产品出库  */
  getProductInventoryOutbound_create: params => {
    return API.POST('api/inventoryProductOutbound/create', params)
  },
}
